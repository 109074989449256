import chosen from './assets/chosen.png';
import timeline from './assets/timeline.png';
import VHS from './assets/vhs.png';
import resume from './assets/resume-v1.png';
import thehelperorg from './assets/thehelperorg-project.png';
import ennui from './assets/text-based-game.png';

export const textSnippet = "A communicator and teamplayer with a diverse background in technical and creative fields.";

export const sections = [
    'All',
    'Tech',
    'Leadership',
    'Communication',
    'Volunteer'
]

export const experienceList = [
    {
        beginDate: 'Aug 2022',
        endDate: 'Present',
        position: 'Technical Project Manager',
        company: 'Barefoot Solutions',
        url: 'https://www.barefootsolutions.com/',
        description: 'This role includes COO, CTO, Project Manager, and Product Manager all wrapped up into one title. I work closely with stakeholders, designers, engineers, and lawyers to get complex and diverse products made on time and within budget.',
        tags: ['Public Speaking', 'Technical Knowledge', 'CSS', 'Quality Assurance', 'AWS', 'Google Analytics 4'],
        sections: ['Tech', 'Public Speaking', 'Communication'],
    },
    {
        beginDate: 'Mar 2021',
        endDate: 'Present',
        position: 'Café Manager',
        company: 'Grove Church',
        url: 'https://sdgrove.org/',
        description: 'As the acting founder of the Grove Café I\'m responsible for training, scheduling, upkeep, and so on. We match a typical Starbucks\' production rate in just a few hours per sunday.',
        tags: ['Leadership', 'Quality Assurance', 'Food & Beverage', 'Team Building', 'Pastoring'],
        sections: ['Volunteer', 'Customer Service', 'Leadership', 'Entrepreneur', 'Communication'],
    },
    {
        beginDate: 'Nov 2021',
        endDate: 'Jun 2022',
        position: 'Fullstack Engineer',
        company: 'togetherAI',
        url: 'https://www.togetherai.com/',
        description: 'Integrated togetherAI with health data provider services to better analyze anomalies of children\'s wellbeing.',
        tags: ['Vue.js', 'Tailwind', 'TypeScript', 'Mobile Development', 'AWS', ],
        sections: ['Tech', 'Communication'],
    },
    {
        beginDate: 'Nov 2020',
        endDate: 'Jun 2021',
        position: 'Lead Barista',
        company: 'Blue Bottle Coffee',
        url: 'https://bluebottlecoffee.com/',
        description: 'Quickly becoming the most esteemed coffee chain in the world, this was also the 2nd most profitable café in the company. We were trained to handle hundreds of customers per day with an outstanding customer service and personable demeanor.',
        tags: ['Customer Service', 'Food & Beverage', 'Leadership', 'Communication'],
        sections: ['Customer Service', 'Communication'],
    },
    {
        beginDate: 'Feb 2021',
        endDate: 'Jun 2021',
        position: 'Instructional Assistant',
        company: 'Diablo Valley College',
        url: 'https://www.dvc.edu/',
        description: 'Normally a role only allowed for graduates that completed a Master\'s program already, I was responsible for all the tasks of a regular college campus instructor.',
        tags: ['Lecturing', 'Teaching', 'Communication', 'Public Speaking', 'Microsoft'],
        sections: ['Communication'],
    },
    {
        beginDate: 'May 2020',
        endDate: 'Jan 2021',
        position: 'Fullstack Engineering Intern',
        company: 'Fyresite',
        url: 'https://www.fyresite.com/',
        description: 'I worked on a variety of high-end products for various companies across the U.S. Partnering with big-name companies like GMG, Fyresite was awarded for “National Excellence” in 2021 and grows an average of 70% YtoY.',
        tags: ['React', 'Flutter', 'CSS', 'Dart', 'MySQL', 'AWS'],
        sections: ['Tech'],
    },
    {
        beginDate: 'Feb 2028',
        endDate: 'Aug 2020',
        position: 'Communication Tutor',
        company: 'Diablo Valley College',
        url: 'https://www.dvc.edu/',
        description: 'Being the top 0.01% of communication students, we were responsible for tutoring our world competitive speed and debate team as well as all other students.',
        tags: ['Communication', 'Public Speaking', 'Tutoring', 'Education'],
        sections: ['Education', 'Communication'],
    },
    {
        beginDate: 'Feb 2021',
        endDate: 'Jun 2021',
        position: 'Lead Computer Science Tutor',
        company: 'Diablo Valley College',
        url: 'https://www.dvc.edu/',
        description: 'Founder, Hiring Manager, and Lead Tutor for our tutoring department. This is a small team of the most expert student-programmers.',
        tags: ['Teaching', 'Communication', 'Public Speaking', 'Tutoring', 'Education'],
        sections: ['Tech', 'Leadership', 'Education', 'Communication'],
    },
    {
        beginDate: 'Jun 2019',
        endDate: 'Jul 2020',
        position: 'Program Instructor',
        company: 'Upward Bound',
        url: 'https://www.dvc.edu/enrollment/ets/trio.html',
        description: 'The sole instructor for federally-funded computer science classes. The target audience is underprivileged first-generation college students. I hand-tailored each curriculum with regular and close student mentorship.',
        tags: ['Lecturing', 'Teaching', 'Communication', 'Public Speaking', 'Tutoring', 'Education'],
        sections: ['Tech', 'Leadership', 'Education', 'Communication'],
    },
    {
        beginDate: 'Jun 2019',
        endDate: 'Jul 2020',
        position: 'Mathematics Tutor',
        company: 'Upward Bound',
        url: 'https://www.dvc.edu/enrollment/ets/trio.html',
        description: 'Calculus tutor for underprivileged first-generaton college students. This was a federally-funded program.',
        tags: ['Teaching', 'Communication', 'Public Speaking', 'Tutoring', 'Education'],
        sections: ['Education', 'Communication'],
    },
    {
        beginDate: 'Mar 2018',
        endDate: 'Jul 2020',
        position: 'Club Founder & Coordinator',
        company: 'Cru',
        url: 'https://www.cru.org/',
        description: 'Club founder/coordinator of the largest Christian club on campus. As the primary facilitator I led multiple Bible studies regularly, hosted events, and welcomed new attendees every week.',
        tags: ['Teaching', 'Communication', 'Leadership', 'Pastoring', 'Public Speaking', 'Education'],
        sections: ['Education', 'Volunteer', 'Leadership', 'Communication'],
    },
    {
        beginDate: 'Jul 2015',
        endDate: 'Jul 2020',
        position: 'Camp Counselor',
        company: 'Koinonia Conference Grounds',
        url: 'https://www.koinoniaconferencegrounds.org/',
        description: 'As a veteran counselor I was responsible for leading regular Bible studies, compassionately instructing/disciplining kids, and directly them to all events.',
        tags: ['Teaching', 'Communication', 'Leadership', 'Pastoring', 'Public Speaking', 'Education'],
        sections: ['Education', 'Volunteer', 'Leadership', 'Communication'],
    },
]



export const projectList = [
    {
        img: chosen,
        title: 'Chosen Music Lessons',
        company: 'Self-Employed',
        url: 'https://www.chosenmusiclessons.com/',
        description: 'A music expert that needed a professional online portfolio.',
        tags: ['React', 'JavaScript', 'CSS', 'UX/UI', 'AWS', 'SendGrid'],
        sections: ['Tech'],
    },
    {
        img: timeline,
        title: 'The Bible Timeline',
        company: 'Self-Employed',
        url: 'https://www.biblepioneer.com/',
        description: 'Building modern UI in areas that have been behind technology for years.',
        tags: ['React', 'JavaScript', 'CSS', 'UX/UI', 'AWS', 'SendGrid'],
        sections: ['Tech', 'Ministry'],
    },
    {
        img: VHS,
        title: 'Van Holten Shots',
        company: 'Self-Employed',
        url: 'https://www.vhshots.com/',
        description: 'Designed and delivered a high-quality, robust photography portfolio with layered IAM and photo management.',
        tags: ['React', 'JavaScript', 'CSS', 'UX/UI', 'AWS', 'SendGrid'],
        sections: ['Tech'],
    },
    {
        img: thehelperorg,
        title: 'The Helper Organization',
        company: 'Self-Employed',
        url: 'https://www.thehelperorg.com/',
        description: 'Putting my professional and pastoral skills to use, I run a non-profit with a close-friend working to fund churches internationally.',
        tags: ['React', 'JavaScript', 'CSS', 'UX/UI', 'AWS', 'SendGrid'],
        sections: ['Tech', 'Ministry'],
    },
    {
        img: ennui,
        title: 'Ennui',
        company: 'Self-Employed',
        url: 'https://github.com/Josiah-Turnquist/Ennui',
        description: 'A text-based open world RPG that I made in my college days. This started as a final project and ended up as a fun side-project.',
        tags: ['Linux/Unix', 'GUI', 'C/C++'],
        sections: ['Tech'],
    },
    {
        img: resume,
        title: 'Résumé V.1',
        company: 'Self-Employed',
        url: 'https://v0.josiahturnquist.com/',
        description: 'My first-ever solo React project. This is where I learned the basics of web design.',
        tags: ['React', 'JavaScript', 'CSS', 'UX/UI', 'AWS', 'SendGrid'],
        sections: ['Tech'],
    },
]